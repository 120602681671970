import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input } from "../components";
import { useNavigate } from "react-router-dom";
import { ValidaForm3 } from "../components/Validacao";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const Form3 = () => {
  const [dados, setDados] = useState({});
  const [erro, setErro] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDados({ ...dados, [name]: value });
  };

  const setData = async () => {
    await setDoc(doc(db, "furg2025", uid), dados, { merge: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const err = ValidaForm3(dados);

    if (err) {
      setErro(err);
      setLoading(false);
    } else {
      setData().then(() => navigate("../form4"));
    }
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2025", uid));

    const data = qry.data();

    if (data?.aceite > "2025-02-01") {
      navigate("../home");
    } else {
      setDados(data);
      setLoading(false);
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  const getAddress = () => {
    setDados({
      ...dados,
      endereco3: dados?.endereco1 || "",
      numero3: dados?.numero1 || "",
      bairro3: dados?.bairro1 || "",
      cep3: dados?.cep1 || "",
      complemento3: dados?.complemento1 || "",
    });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      <Frame
        body={
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="text-start"
            noValidate
          >
            <div className="row mt-2">
              <div className="col-sm-12">
                <Input
                  id="endereco3"
                  label="Endereço de Desembarque"
                  type="text"
                  className={erro.endereco3 && "is-invalid"}
                  placeholder="Digite seu endereço"
                  value={dados?.endereco3 || ""}
                  onChange={handleChange}
                  erro={erro.endereco3}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-4">
                <Input
                  id="numero3"
                  label="Número"
                  type="text"
                  className={erro.numero3 && "is-invalid"}
                  placeholder="000"
                  value={dados?.numero3 || ""}
                  onChange={handleChange}
                  erro={erro.numero3}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  id="bairro3"
                  label="Bairro"
                  type="text"
                  className={erro.bairro3 && "is-invalid"}
                  placeholder="Digite o bairro"
                  value={dados?.bairro3 || ""}
                  onChange={handleChange}
                  erro={erro.bairro3}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  id="cep3"
                  label="CEP"
                  type="number"
                  className={erro.cep3 && "is-invalid"}
                  placeholder="Digite o CEP"
                  value={dados?.cep3 || ""}
                  onChange={handleChange}
                  erro={erro.cep3}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12">
                <Input
                  id="complemento3"
                  label="Complemento"
                  type="text"
                  className={erro.complemento3 && "is-invalid"}
                  placeholder="Ponto de referência (opcional)"
                  value={dados?.complemento3 || ""}
                  onChange={handleChange}
                  erro={erro.complemento3}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="text-end">
                <button
                  className="btn btn-outline-primary"
                  onClick={getAddress}
                  type="button"
                >
                  Usar dados do endereço
                </button>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12 d-grid mt">
                <button type="submit" className="btn btn-primary">
                  ENVIAR
                </button>
              </div>
            </div>
          </form>
        }
        title="Desembarque"
      />
    </>
  );
};

export default Form3;
