export const Nome = (nome = "") => {
  let split = nome.trim().split(" ");

  if (split.length > 1) return split[0] + " " + split.pop();
  else return split;
};

export const ObjetoVazio = (object = {}) => {
  if (Object.keys(object).length > 0) return false;
  else return true;
};

export const MoedaBr = (dados, real = true) => {
  if (!dados) dados = 0;
  if (real) {
    return dados.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  } else {
    return dados.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
  }
};

export const Modalidade = (modalidade = "4") => {
  if ((modalidade === "1")) return "Saída Fixa";
  else if ((modalidade === "2")) return "Rota Fixa";
  else if ((modalidade === "3")) return "VIP";
  else return "Não identificado";
};
