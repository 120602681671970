import moment from "moment";
import "moment/locale/pt-br";
import Logo from "../assets/logo.png";

const VerContrato = (props) => {
  const dados = props.dados;

  return (
    <div className="p-2 justify recuo contrato print">
      <div className="text-center my-4">
        <img src={Logo} alt="Logo Embaixador" width={250} />
      </div>
      <div>
        <p>
          <b>CONTRATADA: EXPRESSO EMBAIXADOR LTDA</b>, pessoa jurídica com sede
          à Rua Giuseppe Garibaldi, nº 660, na cidade de Pelotas/RS, inscrita no
          CNPJ Nº 92.189.612/0001-92, e inscrição estadual nº 093/0180615, neste
          ato representado por sua Diretora Sra. PAULA MARQUES DA FONSECA nos
          termos de seus atos societários.
        </p>
        <p>
          <b>CONTRATANTE: {dados.nome.toUpperCase()}</b>, residente à{" "}
          {dados.endereco1}, {dados.numero1} na cidade de Pelotas, CPF Nº{" "}
          {dados.cpf}, RG Nº
          {dados.rg}, Categoria: {dados.vinculo}.
        </p>
        <p>
          As partes acima qualificadas ajustam entre si o presente contrato,
          mediante as condições e cláusulas que seguem:
        </p>
        <p>
          <b>1. DO OBJETO</b>
        </p>
        <p>
          <b>1.1 </b>
          Por adesão da CONTRATANTE ao presente contrato, a CONTRATADA
          disponibilizará a partir de 24 de março de 2025, o serviço de
          transporte na modalidade Fretamento Urbano/Metropolitano Escolar
          Contínuo, conforme autorizado pela METROPLAN, tendo como origem a
          cidade de Pelotas/RS e destino a cidade de Rio Grande/RS, de segunda à
          sexta-feira, menos feriados.
        </p>
        <p>
          <b>1.2 </b>O transporte objeto deste contrato iniciará com o
          recolhimento da parte CONTRATANTE no perímetro urbano da cidade de
          Pelotas nos dias, turno/horários e em endereço devidamente eleitos e
          indicados pela parte CONTRATANTE em seu cadastro e grade de horários,
          de acordo com modalidade a ser eleita conforme cláusula 2.1, com
          parada em ponto de embarque/desembarque, sito à Rua Manduca Rodrigues
          - esquina com Dom Pedro II, na cidade de Pelotas, local em que será
          designado veículo para realizar o transporte até o destino, FURG
          (Campus Carreiros - sito à Av. Itália, s/n, km 8, Carreiros, Rio
          Grande/RS).
        </p>
        <p>
          <b>1.3 </b>
          Sem prejuízo à cobrança de valor da mensalidade, o serviço poderá ser
          suspenso em período correspondente ao recesso escolar, devido à
          suspensão das atividades acadêmicas, conforme calendário da FURG, bem
          como ter sua prestação alterada de acordo com a programação das
          atividades acadêmicas, como por exemplo em casos de greve - sujeito à
          adequação e ajuste entre as partes.
        </p>
        <p>
          <b>1.4 </b>A CONTRATADA disponibilizará vagas limitadas nos
          dias/turno/horários, de ida e retorno, indicados na grade de horários
          da CONTRATANTE, ficando a contratação e prestação do serviço
          condicionada ao ajuste entre as partes.
        </p>
        <p>
          <b>2. DAS MODALIDADES, PREÇO E CONDIÇÕES DE PAGAMENTO</b>
        </p>
        <p>
          <b>2.1 </b> O serviço objeto deste contrato será prestado em três
          modalidades, com formato e preços a seguir:
        </p>
        <p>
          <b>a)</b> ROTA ÚNICA: Embarque e desembarque em itinerário fixo,
          passando pelas principais vias da cidade de Pelotas, conforme anexo I.
          VALOR R$ 638,00 mensais.
        </p>
        <p>
          <b>b)</b> SAÍDA FIXA: Embarque e desembarque à Rua Manduca Rodrigues,
          esquina com Dom Pedro II, em Pelotas. VALOR R$ 484,00 mensais.
        </p>
        <p>
          <b>c)</b> VIP: Embarque e desembarque porta a porta, nas rotas e
          regiões especificadas no anexo I. VALOR R$ 880,00 mensais.
        </p>
        <p>
          <b>2.2 </b> O valor total do contrato será proporcionalmente calculado
          com base no valor de cada parcela de acordo com a modalidade eleita,
          considerando-se, para tanto, o mês em que se está contratando o
          serviço até o término da vigência deste contrato, nos termos da
          cláusula 5.
        </p>
        <p>
          <b>2.3 </b> O pagamento de cada mensalidade deverá ser realizado até o
          dia 10 de cada mês (ou primeiro dia útil posterior) e poderá ser:
        </p>
        <p>
          <b>a) </b> Via PIX, com comprovação de transferência mediante anexo no
          Portal do Fretamento (https://furg2025.expressoembaixador.com.br/);
        </p>
        <p>
          <b>b) </b> Na sede da CONTRATADA em Pelotas/RS, ou ao preposto da
          empresa, nas seguintes modalidades em dinheiro, PIX, boleto bancário
          ou cartão VISA, MASTER, ELO e BANRICOMPRAS, nas modalidades débito ou
          crédito em até 3 (três parcelas), com parcela mínima não inferior a R$
          25,00 (vinte e cinco reais).
        </p>
        <p>
          <b>2.4 </b> Será concedido DESCONTO de 9% sobre o valor da mensalidade
          quando o pagamento for realizado até o quinto dia útil de cada mês em
          PIX ou em dinheiro na sede da empresa, totalizando-se os valores:
        </p>
        <p>
          <b>a) </b> ROTA ÚNICA: R$ 581,00 mensais.
        </p>
        <p>
          <b>b) </b> SAÍDA FIXA: R$ 440,00 mensais.
        </p>
        <p>
          <b>c) </b> VIP: R$ 800,00 mensais.
        </p>
        <p>
          <b>2.5 </b> O CONTRATANTE poderá solicitar uma única vez a alteração
          da modalidade de prestação dos serviços a qualquer tempo. A
          modificação será efetivada e válida a partir do primeiro dia do mês
          subsequente à solicitação.
        </p>
        <p>
          <b>2.6 </b> Caso não ocorra o pagamento da mensalidade até o dia 10 de
          cada mês (ou primeiro dia útil posterior), será emitido boleto
          bancário no valor total de mensalidade em nome da CONTRATANTE, cujo
          vencimento se dará ao primeiro dia útil seguinte de cada mês,
          ressalvado o pagamento e sua efetiva comprovação por qualquer outro
          meio indicado neste contrato.
        </p>
        <p>
          <b>2.7 </b> A hipótese acima configurará mora para a CONTRATANTE,
          ensejando a incidência de encargos legais sobre o valor da mensalidade
          não paga, e a CONTRATANTE poderá levar o presente termo a protesto,
          observada a legislação processual vigente.
        </p>
        <p>
          <b>2.8 </b> A ausência de pagamento implicará a suspensão do serviço
          até que ocorra o efetivo pagamento de mensalidade vencida (e, sendo o
          caso, da vincenda), inclusive com a eventual incidência de encargos
          legais.
        </p>
        <p>
          <b>3. DO USO ORDINÁRIO DO SERVIÇO</b>
        </p>
        <p>
          <b>3.1 </b> O serviço ordinário se dará a partir da disponibilização
          de 44 passagens (créditos em carteira digital) mensais, que serão
          atualizados mediante pagamento da mensalidade de acordo com a
          modalidade eleita nos termos cláusula 2.
        </p>
        <p>
          <b>3.2 </b> A CONTRATADA confeccionará uma carteira digital de
          identificação diferenciada da CONTRATANTE e sua modalidade de serviço
          contratada.
        </p>
        <p>
          <b>3.3 </b> A carteira digital é de porte obrigatório e o uso do
          serviço fica condicionado à sua apresentação ao preposto da CONTRATADA
          no momento do embarque do veículo.
        </p>
        <p>
          <b>3.4 </b> A cada embarque, a CONTRATANTE será descontado um crédito
          na carteira digital quando apresentada ao preposto da empresa.
        </p>
        <p>
          <b>3.5 </b> A disponibilização/atualização dos créditos fica
          condicionada ao efetivo pagamento da mensalidade, nos termos da
          cláusula 2, com no mínimo um dia de antecedência por parte da
          CONTRATANTE.
        </p>
        <p>
          <b>3.6 </b> A carteira digital com créditos será nominal e
          intransferível, sendo estes válidos tão somente ao mês a que
          corresponde a mensalidade.
        </p>
        <p>
          <b>3.7 </b> Até o dia 10, data de vencimento da mensalidade seguinte,
          a CONTRATANTE poderá utilizar créditos sobressalientes que
          eventualmente não tenham sido utilizadas no mês anterior, limitadas ao
          número de 10 créditos. Nessa hipótese, o número de créditos utilizado
          será compensado no momento da emissão/atualização da mensalidade
          subsequente.
        </p>
        <p>
          <b>3.8 </b> A CONTRATADA recolherá a CONTRATANTE no local, dia e
          turno/horário devidamente selecionados por esta conforme a modalidade
          de serviço eleita, e respectiva grade de horários disponível no
          momento da contratação via Portal do Fretamento. que será conduzida
          até o local de embarque/desembarque à Rua Manduca Rodrigues, em
          Pelotas/RS, nos termos da cláusula 1.2.
        </p>
        <p>
          <b>3.9 </b> Visando a melhor realização do serviço, os trajetos de
          recolhimento e entrega nas regiões indicadas no momento da
          contratação, serão estabelecidos exclusivamente pela CONTRATADA, que
          deverá comunicar o horário exato de recolhimento da CONTRATANTE, a
          qual, por sua vez, não poderá se opor ao cronograma.
        </p>
        <p>
          <b>3.10 </b>No caso de utilização em turno/horário não selecionado na
          grade de horários, a utilização do serviço de forma ordinária fica
          condicionada à disponibilidade de lugar no veículo da CONTRATADA,
          ressalvado o direito da CONTRATANTE de utilizar o serviço de forma
          extraordinária, conforme cláusula 4.
        </p>
        <p>
          <b>3.11 </b>Na modalidade VIP (cláusula 2.1, “c”), em caso de
          dificuldade de acesso, e por questões de logística visando a melhor
          realização do serviço, a CONTRATADA está autorizada a indicar local
          exato de embarque/desembarque, hipótese em que a CONTRATANTE não
          poderá se opor.
        </p>
        <p>
          <b>3.12 </b>Na modalidade VIP (cláusula 2.1, “c”), para melhor
          realização do serviço, a CONTRATANTE deverá estar disponível em 10
          minutos de antecedência ao horário de recolhimento previsto, sendo
          garantida a tolerância de 1 (um) minuto para o embarque.
        </p>
        <p>
          <b>3.13</b>Na modalidade VIP (cláusula 2.1, “c”), após o decurso do
          prazo de tolerância a CONTRATADA fica desobrigada a retornar ao local
          de recolhimento naquela data, independentemente da causa.
        </p>
        <p>
          <b>3.14 </b>Na modalidade VIP, caso solicitado novo horário de
          recolhimento fora dos prazos estabelecidos neste contrato, a
          CONTRATADA poderá exigir, a título de penalidade, o valor de ticket
          equivalente ao número de serviços adicionalmente prestados.
        </p>
        <p>
          <b>3.15 </b>Na modalidade VIP (cláusula 2.1, “c”), em caso de
          necessária alteração de turno/horário, ou eventual não utilização do
          serviço objeto deste contrato, a CONTRATANTE deverá comunicar e
          solicitar à CONTRATADA pelo telefone (53) 98122-2534 com antecedência
          mínima de um dia à utilização do serviço.
        </p>
        <p>
          <b>3.16 </b>Na modalidade VIP (cláusula 2.1, “c”), a omissão da
          CONTRATANTE desobriga a CONTRATADA de alterar a cronologia e horário
          de recolhimento previsto, de modo que será dada tolerância de 1 (um)
          minuto para a CONTRATANTE.
        </p>
        <p>
          <b>3.17 </b>Na modalidade VIP (cláusula 2.1, “c”), a CONTRATADA fica
          autorizada a disponibilizar o número de veículos de recolhimento e
          entrega nas regiões indicadas no momento da contratação, bem como
          realizar baldeamento de acordo com a demanda necessária, limitado ao
          máximo de quatro.
        </p>
        <p>
          <b>3.18 </b>No local de embarque/desembarque estabelecido à cláusula
          1.2 a CONTRATADA indicará o número de veículos necessários a realizar
          o transporte até o destino final, visando a melhor realização do
          serviço.
        </p>
        <p>
          <b>3.19 </b>Os horários de retorno do Campus são pré-definidos pela
          exclusivamente pela CONTRATADA, conforme destacado no momento da
          contratação, de modo que o atraso, e o não cumprimento do horário é de
          responsabilidade exclusiva por parte da CONTRATANTE.
        </p>
        <p>
          <b>3.20 </b>Em períodos especiais e excepcionais, como o de exames, a
          CONTRATANTE será obrigada a comunicar a CONTRATADA sua vontade de
          utilizar o serviço, tanto para a ida como para retorno, em até 24
          (vinte e quatro) horas de antecedência.
        </p>
        <p>
          <b>3.21 </b>Em caso de problemas mecânicos, a CONTRATADA deverá
          substituir o veículo em até duas horas.
        </p>
        <p>
          <b>3.22 </b>Em caso de sinistro, greve e/ou casos que não sejam por
          culpa e/ou responsabilidade da CONTRATADA ou seu preposto, não haverá
          nenhum tipo de abatimento, abono, indenização e/ou ressarcimento em
          favor da CONTRATANTE.
        </p>
        <p>
          <b>
            4. DO USO EXTRAORDINÁRIO DO SERVIÇO: EMBARQUE EM TRANSPORTE NA LINHA
            REGULAR PELOTAS X RIO GRANDE
          </b>
        </p>
        <p>
          <b>4.1 </b>De forma extraordinária, independentemente da modalidade
          eleita, a CONTRATANTE poderá utilizar do transporte regular na linha
          atendida pela CONTRATADA, (Pelotas x Rio Grande x Pelotas) de segunda
          a domingo, na modalidade comum (menos feriados), no trajeto Pelotas x
          Vila Maria x Pelotas.
        </p>
        <p>
          <b>4.2 </b>No ato de embarque da utilização extraordinária, a
          CONTRATANTE deverá apresentar sua carteira digital com créditos
          válidos ao preposto da CONTRATADA. Após conferência, será emitido o
          bilhete de passagem eletrônico (BPe embarcado) em valor equivalente ao
          da passagem adquirida nos termos da cláusula 3.1 deste contrato.
        </p>
        <p>
          <b>4.3 </b>Caso a CONTRATANTE não apresente a carteira digital do
          fretamento quando utilizar a linha regular, será cobrada a diferença
          entre o valor da passagem adquirida nos termos da cláusula 3.1 e o
          valor da tarifa da linha regular, conforme tabela vigente da METROPLAN
          ao momento do uso.
        </p>
        <p>
          <b>4.4 </b>Fica autorizado o uso da carteira digital em pontos de
          paradas e modalidade de transporte diferentes dentro da linha Pelotas
          x Rio Grande, desde que sejam válidos os créditos constantes da
          carteira digital, bem como seja realizado o pagamento de diferença
          entre o valor individual da passagem da CONTRATANTE e a tarifa vigente
          conforme tabela da METROPLAN ao momento do uso.
        </p>
        <p>
          <b>4.5 </b>Aplica-se para os casos de utilização extraordinária o
          disposto na cláusula 3.7.
        </p>
        <p>
          <b>5. DO PRAZO DE VIGÊNCIA DO CONTRATO</b>
        </p>
        <p>
          <b>5.1 </b>O presente contrato terá vigência a contar de sua
          assinatura até 31/12/2025, e torna sem efeito qualquer contratação
          anterior entre as partes.
        </p>
        <p>
          <b>5.2 </b>Em caso de necessária adequação ao calendário escolar, o
          contrato poderá ser renovado por mais um mês mediante simples
          manifestação das partes, seguida do devido pagamento da mensalidade
          correspondente, nos termos da cláusula 2.
        </p>
        <p>
          <b>5.3 </b> Após o escoamento dos prazos previstos nas cláusulas
          acima, eventual renovação estará sujeita à manifestação das partes,
          ressalvado reajuste de preço, respeitando-se o aumento de tarifa
          aprovado pela METROPLAN (poder concedente).
        </p>
        <p>
          <b>6. DAS HIPÓTESES DE RESCISÃO</b>
        </p>
        <p>
          <b>6.1 </b>O presente contrato poderá ser rescindido na seguintes
          hipóteses:
        </p>
        <p>
          <b>a)</b> MANIFESTAÇÃO EXPRESSA: A CONTRATANTE poderá informar seu
          interesse em rescindir o contrato, respeitada a antecedência mínima de
          30 (trinta) dias.
        </p>
        <p>
          <b>b)</b> JUSTA CAUSA: Quando a CONTRATANTE desrespeitar em três ou
          mais ocasiões as disposições gerais de uso e prestação do serviço,
          conforme cláusula 3.
        </p>
        <p>
          <b>c)</b> INADIMPLÊNCIA: Quando houver o atraso no pagamento de duas
          ou mais mensalidades, em desacordo com a cláusula 2, haverá rescisão
          automática.
        </p>
        <p>
          <b>6.2 </b>Qualquer seja a hipótese acima estipulada, sempre que se
          concretizar a mora prevista na cláusula 2.5, a CONTRATANTE não se
          exime do pagamento da mensalidade em que se deu a rescisão, ficando a
          CONTRATADA autorizada a utilizar dos meios adequados de cobrança caso
          existam valores pendentes.
        </p>
        <p>
          <b>6.3 </b>A CONTRATANTE fica dispensada dos pagamentos descritos na
          cláusula 6.2 quando manifestar interesse na rescisão e comprovar o
          recebimento do benefício do passe livre estudantil por parte do Poder
          Concedente Estadual, sem prejuízo inclusive quanto a valores
          pendentes, desde que comprovado seu ingresso.
        </p>
        <p>
          <b>6.4 </b>A CONTRATADA não realizará nenhum estorno ou ressarcimento
          por passagens (tickets/créditos) não utilizados qualquer seja a
          hipótese de rescisão.
        </p>
        <p>
          <b>7. DA MULTA CONTRATUAL</b>
        </p>
        <p>
          <b>7.1 </b> Na hipótese de rescisão por justa causa (cláusula 6.1,
          “b”), a CONTRATANTE será obrigada a pagar, a título de multa
          contratual, o valor correspondente a uma mensalidade.
        </p>
        <p>
          <b>8. DO CONSENTIMENTO</b>
        </p>
        <p>
          <b>8.1 </b>As Partes reconhecem expressamente a veracidade,
          autenticidade, integridade, validade e eficácia do constante e
          declarado neste instrumento em meio digital, segundo disposto nos
          artigos 219 e 225 do Código Civil, assim como expressam concordância
          que o presente poderá ser assinado de forma digital, eletrônica ou
          manuscrita.
        </p>
        <p>
          <b>8.2 </b>Fica certo, também, que as assinaturas das Partes não
          precisam necessariamente ser apostas na mesma página de assinaturas
          deste instrumento, e que a troca de páginas de assinaturas, assinadas
          escaneadas ou digitalizadas pode se dar inclusive mediante por simples
          aceite a termos, gerando documento eletrônico automático (como por
          exemplo ".pdf"), situação que é tão válida e produz os mesmos efeitos
          que eventual assinatura original de cada Parte aposta neste
          instrumento.
        </p>
        <p>
          <b>9. DO USO E TRATAMENTO DE DADOS PESSOAIS DAS PARTES</b>
        </p>
        <p>
          <b>9.1 </b>As Partes declaram que reconhecem que as informações
          coletadas para confecção deste contrato, tais como nome, RG, CPF,
          profissão, endereço, email, telefone, bem como dados bancários e de
          cartão de débito/crédito da CONTRATANTE serão armazenados para
          tratamento por parte da CONTRATADA, em plena conformidade com as
          condições da Lei nº 13.709/18 (Lei Geral de Proteção de Dados - LGPD).
        </p>
        <p>
          <b>9.2 </b>A CONTRATADA atua como controladora no tratamento referidos
          dados da CONTRATANTE (titular), nos termos do que dispõe o art. 5º da
          LGPD.
        </p>
        <p>
          <b>9.3 </b>Nos termos do art. 7º, I, II, III, V, VII, IX e X e 8º da
          LGPD, a CONTRATANTE manifesta consentimento para compartilhamento de
          seus dados tanto para a finalidade da boa e adequada prestação do
          serviço objeto do contrato, sobretudo para fins de controle interno
          operacional, financeiro, e fiscal por parte da CONTRATADA, bem como
          para fins de necessário compartilhamento com a METROPLAN, enquanto
          poder concedente, nos termos da legislação estadual vigente, bem como
          a demais órgãos vinculados à Administração Pública Direta e Indireta,
          sobretudo autoridades administrativas e judiciais no devido exercício
          de suas competências.
        </p>
        <p>
          <b>9.4 </b>A CONTRATANTE também manifesta consentimento e autoriza o
          compartilhamento de seus dados com terceiros legitimados a defender os
          interesses das Partes no exercício do presente contrato.
        </p>
        <p>
          <b>9.5 </b>Fica garantido à CONTRATANTE, nos termos do art. 9º da
          LGPD, a consulta facilitada e gratuita sobre detalhes gerais do
          tratamento, tais como a forma do tratamento e a integralidade e
          proteção de seus dados, mediante simples solicitação ao encarregado da
          CONTRATADA, por telefone (53) 3278-7000 (ramal 224) ou por email
          juridico@expressoembaixador.com.br.
        </p>
        <p>
          <b>9.6 </b>Os dados poderão ser armazenados e compartilhados no
          exercício de exigências legais mesmo após eventual rescisão do
          contrato, consoante as hipóteses legais de conservação, previstas no
          art. 16 da LGPD.
        </p>
        <p>
          <b>9.7 </b>Nos termos do art. 18 da LGPD, a CONTRATANTE poderá
          solicitar livremente a exclusão de seus dados pessoais tratados
          mediante simples manifestação direcionada à CONTRATADA, na pessoa de
          seu encarregado.
        </p>
        <p>
          <b>10. DO FORO</b>
        </p>
        <p>
          <b>10.1 </b> As partes elegem o foro da comarca de Pelotas/RS, com
          exclusão de qualquer outro, para resolver qualquer litígio que surja
          em razão do presente contrato.
        </p>
      </div>
      <div className="text-end my-3">
        {dados?.aceite
          ? moment(dados?.aceite).format(
              "[Assinado digitalmente no dia] DD/MM/YYYY [às] HH:mm:ss."
            )
          : moment(dados?.aceite).format("[Pelotas, ] DD [de] MMMM [de] YYYY")}
      </div>
    </div>
  );
};

export default VerContrato;
