const Loading = () => {
  return (
    <div className="bg-full">
      <div className="loading rounded">
        <div className="p-4 text-center">
          <h5 className="p-2">Carregando</h5>
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
