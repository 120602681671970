import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";

export const ValidaUid = async () => {
  if (auth.currentUser) return auth.currentUser.uid;
  else {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) resolve(user.uid);
        else resolve(0);
      });
    });
  }
};
