import { useState, useEffect } from "react";
import { Frame, Input, Loading } from "../components/";
import { auth } from "../config/firebase";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import {
  ValidaEmail,
  ValidaSenha,
  ValidaSenha2,
  ValidaCadastro,
} from "../components/Validacao";
import { useNavigate } from "react-router-dom";

const Cadastro = () => {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [senha2, setSenha2] = useState("");
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState({});

  const navigate = useNavigate();

  const logado = () => {
    const uid = auth.currentUser?.uid;

    if (uid) navigate("../home");
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) navigate("../home");
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const erroEmail = ValidaEmail(email);
    const erroSenha = ValidaSenha(senha);
    const erroSenha2 = ValidaSenha2(senha, senha2);

    if (erroEmail || erroSenha || erroSenha2) {
      setErro({ email: erroEmail, senha: erroSenha, senha2: erroSenha2 });
      setLoading(false);
    } else {
      createUserWithEmailAndPassword(auth, email, senha)
        .then(() => {
          setLoading(false);
          alert("Cadastro realizado com sucesso!");
          navigate("../home");
        })
        .catch((err) => {
          setErro(ValidaCadastro(err.code));
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    logado();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Frame
        body={
          <div className="px-3">
            <form autoComplete="off" onSubmit={handleSubmit} noValidate>
              <div className="row mt-2">
                <Input
                  id="email"
                  label="Email"
                  type="email"
                  className={erro.email && "is-invalid"}
                  placeholder="Digite seu email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  erro={erro.email}
                  autoFocus
                />
              </div>
              <div className="row mt-2">
                <Input
                  id="senha"
                  label="Senha"
                  type="password"
                  className={erro.senha && "is-invalid"}
                  placeholder="Digite sua senha"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  erro={erro.senha}
                />
              </div>
              <div className="row mt-2">
                <Input
                  id="confirmacao"
                  label="Confirme sua senha"
                  type="password"
                  className={erro.senha2 && "is-invalid"}
                  placeholder="Repita sua senha"
                  value={senha2}
                  onChange={(e) => setSenha2(e.target.value)}
                  erro={erro.senha2}
                />
              </div>
              <div className="row mt-3">
                <button type="submit" className="btn btn-primary ">
                  CADASTRAR
                </button>
              </div>
            </form>
          </div>
        }
        footer={
          <div>
            <hr />
            <div className="mt-1">
              Já possui cadastro?{" "}
              <a href="/login" className="text-dark">
                Faça login
              </a>
            </div>
            <div className="mt-1">
              Novo por aqui?{" "}
              <a href="instrucoes" className="text-dark">
                Veja como funciona
              </a>
            </div>
          </div>
        }
        title="Cadastre-se aqui"
        logo
        copyright
      />
    </>
  );
};

export default Cadastro;
