import { useState, useEffect } from "react";
import { Frame, Loading, Header } from "../components";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Button, Image, Modal, Tab, Tabs } from "react-bootstrap";

import Rota from "../assets/rota.png";

const Form7 = () => {
  const [dados, setDados] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalidade, setModalidade] = useState(0);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSelect = (selected) => {
    setModalidade(selected);
    handleShow();
  };

  const setData = async () => {
    await setDoc(
      doc(db, "furg2025", uid),
      { modalidade: modalidade },
      { merge: true }
    );
  };

  const handleSubmit = () => {
    handleClose();
    setLoading(true);
    setData().then(() => navigate("../inicio"));
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2025", uid));

    const data = qry.data();

    if (data?.aceite > "2025-02-01") {
      navigate("../home");
    } else {
      setDados(data);
      setLoading(false);
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}

      <Header nome={dados?.nome} foto={dados?.urlFoto} />

      {!loading && (
        <Frame
          body={
            <Tabs
              defaultActiveKey={dados?.modalidade || "1"}
              id="justify-tab-example"
              variant="tabs"
              justify
            >
              <Tab eventKey="1" title="Saída Fixa">
                <div className="px-3 py-4 justify tab-border">
                  <h6>Modalidade Saída Fixa:</h6>

                  <p>
                    Embarque e desembarque na rua Rua Manduca Rodrigues, esquina
                    com General Telles (lateral do Supermercado Guanabara).
                  </p>

                  <h6>Horários de saída:</h6>
                  <ul>
                    <li>Manhã: 06:45</li>
                    <li>Tarde: 12:10</li>
                    <li>Noite: 17:45</li>
                  </ul>

                  <h6>Valor:</h6>
                  <ul>
                    <li>R$ 484,00 mensais.</li>
                    <li>R$ 440,00 pagando até o quinto dia útil.</li>
                  </ul>

                  <h6>Benefícios:</h6>
                  <ul>
                    <li>
                      44 créditos de embarque que também podem ser utilizados
                      livremente na linha Pelotas x Rio Grande x Pelotas.
                    </li>
                  </ul>
                </div>

                <div className="mt-3 d-grid">
                  <Button variant="primary" onClick={() => handleSelect("1")}>
                    Selecionar essa modalidade de serviço
                  </Button>
                </div>
              </Tab>

              <Tab eventKey="2" title="Rota Única">
                <div className="px-3 py-4 justify tab-border">
                  <h6>Modalidade Rota Única:</h6>

                  <p>
                    Embarque e desembarque em itinerário fixo, passando pelas
                    principais vias da cidade de Pelotas.
                    <br />
                    Verifique atentamente no mapa abaixo o local de embarque
                    mais próximo de sua residência:
                  </p>

                  <div className="text-center mb-4">
                    <Image src={Rota} className="rounded" fluid />
                    <div>
                      {" "}
                      <small>
                        <a
                          href="https://earth.google.com/earth/d/1EjDC4x9r5gbKSY5fEHXEoQXGaDbB5LWr?usp=sharing"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Clique aqui
                        </a>{" "}
                        para ver no Google Earth
                      </small>
                    </div>
                  </div>

                  <h6>Valor:</h6>
                  <ul>
                    <li>R$ 638,00 mensais.</li>
                    <li>R$ 581,00 pagando até o quinto dia útil.</li>
                  </ul>

                  <h6>Benefícios:</h6>
                  <ul>
                    <li>
                      44 créditos de embarque que também podem ser utilizados
                      livremente na linha Pelotas x Rio Grande x Pelotas.
                    </li>
                  </ul>
                </div>

                <div className="mt-3 d-grid">
                  <Button variant="primary" onClick={() => handleSelect("2")}>
                    Selecionar essa modalidade de serviço
                  </Button>
                </div>
              </Tab>

              <Tab eventKey="3" title="VIP">
                <div className="px-3 py-4 justify tab-border">
                  <h6>Modalidade VIP:</h6>
                  <p>
                    Embarque e desembarque porta a porta dentro do perímetro
                    atendido.
                  </p>
                  <h6>Valor:</h6>
                  <ul>
                    <li>R$ 880,00 mensais.</li>
                    <li>R$ 800,00 pagando até o quinto dia útil.</li>
                  </ul>
                  <h6>Benefícios:</h6>
                  <ul>
                    <li>
                      44 créditos de embarque que também podem ser utilizados
                      livremente na linha Pelotas x Rio Grande x Pelotas.
                    </li>
                  </ul>
                </div>

                <div className="mt-3 d-grid">
                  <Button variant="primary" onClick={() => handleSelect("3")}>
                    Selecionar essa modalidade de serviço
                  </Button>
                </div>
              </Tab>
            </Tabs>
          }
          title="Tipo de Serviço"
        />
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Você tem certeza que deseja selecionar essa modalidade?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Voltar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Form7;
