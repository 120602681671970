import { useState, useEffect } from "react";
import { Frame, Loading, Header } from "../components";
import { db, auth } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import Logo from "../assets/logo2.png";
import moment from "moment";
import { Modalidade } from "../components/Formatar";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [dados, setDados] = useState({});

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2025", uid));

    const data = qry.data();

    if (data?.aceite > "2025-02-01") setDados(data);

    console.log(data.uid)

    setLoading(false);
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header nome={dados?.nome} foto={dados?.urlFoto} />

      {loading && <Loading />}

      {!loading && !dados?.status && <Novo />}
      {!loading && dados?.status === "d" && <Devedor />}
      {!loading && dados?.status === "p" && <Pendente />}
      {!loading && dados?.status === "i" && <Inativo />}
      {!loading && dados?.status === "c" && <Cancelado />}
      {!loading && dados?.status === "a" && <Aprovado dados={dados} />}
    </>
  );
};

export default Home;

const Novo = () => {
  return (
    <Frame
      body={
        <div className="justify px-3">
          <p>
            Preencha seus dados para utilizar o nosso serviço de fretamento
            universitário para FURG 2025.
          </p>
          <p>
            Se você é novo no serviço, <a href="./introducao">clique aqui</a> e
            veja como funciona.
          </p>
          <div className="alert alert-warning">
            Cadastros realizados antes das 14:00 estão aptos para embarque no
            próximo dia útil.
          </div>

          <div className="mt-4 text-end">
            <a className="btn btn-primary" href="./form1">
              Preencha aqui
            </a>
          </div>
        </div>
      }
      title="Complete seu cadastro"
    />
  );
};

const Pendente = () => {
  return (
    <Frame
      body={
        <div className="justify px-3">
          <p>
            Sua solicitação de contratação do serviço foi recebida com sucesso.
            Agora, vamos validar as informações fornecidas e caso identifiquemos
            alguma inconsistência, entraremos em contato.
          </p>

          <p>
            Após a aprovação da sua solicitação, você terá acesso à sua
            carteirinha digital e aos demais recursos disponíveis no portal.
          </p>

          <p>Agradecemos por escolher nossos serviços!</p>

          <div className="alert alert-warning">
            Cadastros realizados antes das 14:00 estão aptos para embarque no
            próximo dia útil.
          </div>
        </div>
      }
      title="Cadastro em Análise"
    />
  );
};

const Aprovado = (props) => {
  const saldoMes = "saldo" + moment().format("MMYY");

  return (
    <Frame
      body={
        <div className="container-fluid">
          <div className="col-sm-6 mx-auto">
            <img className="mt-2" src={Logo} width={250} alt="logo" />
            <h4 className="mt-2 py-1 bg-orange bg-gradient text-white rounded-1">
              FRETAMENTO FURG
            </h4>

            <div className="mt-2">
              <QRCode size={150} value={"378|" + props.dados.uid} />
            </div>

            <img
              src={props.dados.urlFoto}
              alt="Usuário"
              width="150"
              height="150"
              className="d-inline rounded-circle mt-2"
            />

            <div className="mt-2">
              <h4 className="mt-2 py-1 bg-orange bg-gradient text-white rounded-1 text-capitalize">
                {props.dados.nome}
              </h4>
            </div>
            <div className="mt-2 mx-auto fs-5">
              <div className="mt-1">
                <span className="fw-bold azul-logo">CPF: </span>
                {props.dados.cpf}
              </div>
              <div className="mt-1">
                <span className="fw-bold azul-logo">RG: </span>
                {props.dados.rg}
              </div>
              <div className="mt-1">
                <span className="fw-bold azul-logo">Tipo: </span>
                {Modalidade(props.dados.modalidade)}
              </div>
              <div className="mt-1">
                <span className="fw-bold azul-logo">Saldo: </span>
                {props.dados[saldoMes] || "0"}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

const Inativo = () => {
  return (
    <Frame
      body={
        <div className="justify px-3">
          <p>
            Seu cadastro em nosso serviço de fretamento da FURG está inativo.
          </p>
          <p>
            Se deseja ativá-lo novamente, entre em contato pelo WhatsApp do
            fretamento: (53) 98122-2534.
          </p>
        </div>
      }
      title="Cadastro Inativo"
    />
  );
};

const Cancelado = () => {
  return (
    <Frame
      body={
        <div className="justify px-3">
          <p>
            Seu cadastro em nosso serviço de fretamento da FURG está cancelado.
          </p>
          <p>
            Se deseja ativá-lo novamente, entre em contato pelo WhatsApp do
            fretamento: (53) 98122-2534.
          </p>
        </div>
      }
      title="Cadastro Cancelado"
    />
  );
};

const Devedor = () => {
  return (
    <Frame
      body={
        <div className="justify px-3">
          <p>
            Seu cadastro em nosso serviço de fretamento da FURG está com uma
            pendência financeira.
          </p>
          <p>
            Por gentileza, entre em contato conosco pelo WhatsApp: (53)
            98122-2534.
          </p>
        </div>
      }
      title="Cadastro Devedor"
    />
  );
};
