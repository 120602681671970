import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input, ValidaUid } from "../components";
import { db, storage } from "../config/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
  orderBy,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { Button, Col, Row, Modal, Table } from "react-bootstrap";
import { MoedaBr } from "../components/Formatar";
import moment from "moment";

const Financeiro = () => {
  const [dadosUsuario, setDadosUsuario] = useState({});
  const [dadosFinanceiro, setDadosFinanceiro] = useState({});
  const [dadosAlteracoes, setDadosAlteracoes] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const buscaDadosUsuario = async (uid) => {
    const qry = await getDoc(doc(db, "furg2025", uid));

    return new Promise((resolve) => {
      const data = qry.data();

      if (data?.status === "a" && data?.aceite > "2025-02-01")
        resolve(data);
      else resolve(0);
    });
  };

  const buscaDadosFinanceiro = async (modalidade) => {
    const qry = await getDocs(
      query(
        collection(db, "financeiro"),
        where("sistema", "==", "furg2025"),
        where("modalidade", "==", modalidade),
        where("ativo", "==", "1"),
        orderBy("dataDesconto", "asc")
      )
    );

    let docs = [];

    qry.forEach((doc) => {
      docs.push(doc.data());
    });

    return new Promise((resolve) => {
      resolve(docs);
    });
  };

  const uploadArquivo = async (file, docPath, docUrl) => {
    if (!file) return;

    setLoading(true);

    const path = ref(storage, `furg2025/${dadosUsuario.uid}/${docPath}`);

    const upload = uploadBytesResumable(path, file, file.type);

    upload.on(
      "state_changed",
      () => {},
      () => {},
      () => {
        getDownloadURL(upload.snapshot.ref).then((url) => {
          setDadosAlteracoes({
            ...dadosAlteracoes,
            [docUrl]: url,
            [docPath]: "Enviado",
          });
          setDadosUsuario({ ...dadosUsuario, [docUrl]: url });
          setLoading(false);
        });
      }
    );
  };

  const enviaDados = async () => {
    setLoading(true);

    await setDoc(doc(db, "furg2025", dadosUsuario.uid), dadosAlteracoes, {
      merge: true,
    });

    setModalShow(true);
  };

  const fechaModal = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    const buscaDados = async () => {
      let uid = await ValidaUid();

      if (uid) {
        const usuario = await buscaDadosUsuario(uid);

        if (usuario) {
          setDadosUsuario(usuario);

          const financeiro = await buscaDadosFinanceiro(usuario.modalidade);
          if (financeiro) setDadosFinanceiro(financeiro);

          setLoading(false);
        } else {
          navigate("../home");
        }
      } else {
        navigate("../login");
      }
    };

    buscaDados();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dadosUsuario?.nome} foto={dadosUsuario?.urlFoto} />

      <Frame
        body={
          <>
            <Table bordered striped hover size="sm" className="small my-4">
              <thead className="align-middle">
                <tr>
                  <th>Mes</th>
                  <th>Valor Normal</th>
                  <th>Valor Antecipado</th>
                  <th>Desconto até</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {dadosFinanceiro[0] &&
                  dadosFinanceiro.map((doc, index) => (
                    <tr key={index}>
                      <td>{doc.descricao}</td>
                      <td>{MoedaBr(doc.valorMesNormal)}</td>
                      <td>{MoedaBr(doc.valorMesDesconto)}</td>
                      <td>{moment(doc.dataDesconto).format("DD/MM/YY")}</td>

                      <td>
                        {dadosUsuario?.["pagto" + doc.competencia] ||
                          "Não Pago"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            <div className="my-4">
              <p>
                <i>
                  Para pagar sua mensalidade, faça um pix para a chave abaixo e
                  encaminhe o comprovante no mês correspondente.
                </i>
              </p>
            </div>

            <div className="my-4">
              <h5>Chave Pix CNPJ:</h5>
              <h5> 92.189.612/0001-92</h5>
            </div>

            {dadosFinanceiro[0] &&
              dadosFinanceiro.map(
                (doc, index) =>
                  !dadosUsuario?.["pagto" + doc.competencia] && (
                    <Row className="text-start m-2" key={index}>
                      <Col>
                        <Input
                          id={"urlPagto" + doc.competencia}
                          label={doc.descricao}
                          type="file"
                          url={dadosUsuario?.["urlPagto" + doc.competencia]}
                          accept=".jpg, .jpeg, .png, .pdf"
                          onChange={(e) =>
                            uploadArquivo(
                              e.target.files[0],
                              "pagto" + doc.competencia,
                              "urlPagto" + doc.competencia
                            )
                          }
                        />
                      </Col>
                    </Row>
                  )
              )}

            {Object.keys(dadosAlteracoes).length > 0 && (
              <Row className="mx-2 mt-4">
                <Col className="d-grid">
                  <Button onClick={enviaDados}>Enviar</Button>
                </Col>
              </Row>
            )}
          </>
        }
        title="Financeiro"
      />

      <Modal show={modalShow} onHide={fechaModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Comprovante enviado com Sucesso!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={fechaModal}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Financeiro;
